import { useState } from 'react';

export const useStake = () => {
    const [isStaking, setIsStaking] = useState(null);
    const [stakeSuccess, setStakeSuccess] = useState(null);

    const createStakeRC = async (curUserID, userWallet, stakeAmount, days) => {
        setIsStaking(true);

        const response = await fetch('https://api.ridechain.io/claim/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                wallet: userWallet,
                withdrawal_amount: stakeAmount,
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setTimeout(() => setIsStaking(false), 3000);
        }
        if (response.ok) {
            setStakeSuccess(true);
            setTimeout(() => setIsStaking(false), 3000);
        }
    }
    
    const createStakeRCC = async (curUserID, userWallet, stakeAmount, days) => {
        setIsStaking(true);

        const response = await fetch('https://api.ridechain.io/claim/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                user_id: curUserID,
                wallet: userWallet,
                withdrawal_amount: stakeAmount,
            })
        })
        const json = await response.json();
        if (!response.ok) {
            setTimeout(() => setIsStaking(false), 3000);
        }
        if (response.ok) {
            setStakeSuccess(true);
            setTimeout(() => setIsStaking(false), 3000);
        }
    }

    return { isStaking, setIsStaking, stakeSuccess, setStakeSuccess, createStakeRC, createStakeRCC };
}