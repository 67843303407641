import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import { setLanguage, t } from '../translation.js';

import CustomBtn from '../assets/img/custom-btn.png';
import RCToken from '../assets/img/rc-token.png';

// hooks
import { useOrder } from '../hooks/useOrder';
import { useClaim } from '../hooks/useClaim';

const MarketPage = () => {

	const { isOrdering, setIsOrdering, orderSuccess, setOrderSuccess, createSellOrder } = useOrder();
	const { isClaiming, setIsClaiming, claimSuccess, setClaimSuccess, createRCClaim } = useClaim();

	const blockchain = useSelector((state) => state.blockchain);

	const [otcMarketToken, setOtcMarketToken] = useState("RC");

	const [rcPlusTrade, setRCPlusTrade] = useState("Buy");

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
	  .then((results) => {
		console.log("USDT Balance:", results);
		setUSDTBalance(results);
	  });
	};

	const [rcBalance, setRCBalance] = useState(0);
	const getUserRCBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x3dE5494f9c61f6dE91b5eB7db0b1CF7c898fF370").call()
	  .then((results) => {
		console.log("RC Balance:", results);
		setRCBalance(results);
	  });
	};

	const [rcPlusBalance, setRCPlusBalance] = useState(0);
	const getUserRCPlusBalance = () => {
	  blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x5b7C9887a800490277b00fE4A30cb149e5cdbbc1").call()
	  .then((results) => {
		console.log("RCPlus Balance:", results);
		setRCPlusBalance(results);
	  });
	};

	const [rcpPrice, setRCPPrice] = useState(0);
	const getRCPPrice = () => {
	  blockchain.tokenPair.methods.getReserves().call()
	  .then((results) => {
		console.log("Token Pair Price:", results._reserve0/results._reserve1);
		setRCPPrice(results._reserve0/results._reserve1);
	  });
	};

	const [rcAllowance, setRCAllowance] = useState(0);
	const checkRCAllowance = () => {
	  blockchain.rcToken.methods.allowance(blockchain.account, "0x43aC236E152d48EC667C15ccB552f8038eC753f7").call()
	  .then((results) => {
		console.log("RC Allowance:", results);
		setRCAllowance(results);
	  });
	};

	const [rcPlusAllowance, setRCPlusAllowance] = useState(0);
	const checkRCPlusAllowance = () => {
	  blockchain.rcPlusToken.methods.allowance(blockchain.account, "0x43aC236E152d48EC667C15ccB552f8038eC753f7").call()
	  .then((results) => {
		console.log("RC Plus Allowance:", results);
		setRCPlusAllowance(results);
	  });
	};

	const [rcPlusOrderData, setRcPlusOrderData] = useState(null);
	const fetchRcPlusOrderData = () => {
	  blockchain.rcPlusHandlerV2.methods.getUserOrderInfos(blockchain.account).call()
	  .then((results) => {
		console.log("User RC Plus Order Data:", results);
		setRcPlusOrderData(results);
	  });
	};

	const [approvingRC, setApprovingRC] = useState(false);
	const approveRC = () => {
		setApprovingRC(true);
		blockchain.rcToken.methods
		.approve("0x43aC236E152d48EC667C15ccB552f8038eC753f7", blockchain.web3.utils.toWei((10000000).toString(), "ether"))
		.send({
			gasLimit: 80000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setApprovingRC(false);
		})
		// Transaction success
		.then((receipt) => {
			setTimeout(() => checkRCAllowance(), 3000);
			setTimeout(() => checkRCPlusAllowance(), 3000);
			setTimeout(() => setApprovingRC(false), 5000);
		});
	};

	const [approvingRCPlus, setApprovingRCPlus] = useState(false);
	const approveRCPlus = () => {
		setApprovingRCPlus(true);
		blockchain.rcPlusToken.methods
		.approve("0x43aC236E152d48EC667C15ccB552f8038eC753f7", blockchain.web3.utils.toWei((100000000000).toString(), "ether"))
		.send({
			gasLimit: 80000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setApprovingRCPlus(false);
		})
		// Transaction success
		.then((receipt) => {
			setTimeout(() => checkRCPlusAllowance(), 3000);
			setTimeout(() => setApprovingRCPlus(false), 5000);
		});
	};

	const [buyingRCP, setBuyingRCP] = useState(false);
	const [buyRCPSuccess, setBuyRCPSuccess] = useState(false);
	const buyRCP = (_amount) => {
		if (userData && userData.rct < 20) {
			setRCPAmountError(t('insufficient-rct'));
			return;
		}
		setBuyingRCP(true);
		blockchain.rcPlusHandlerV2.methods
		.swapRCForRCPlus(blockchain.web3.utils.toWei((_amount).toString(), "ether"), ["0x3dE5494f9c61f6dE91b5eB7db0b1CF7c898fF370", "0x5b7c9887a800490277b00fe4a30cb149e5cdbbc1"])
		.send({
			gasLimit: 1050000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setBuyingRCP(false);
			setInvalidRCPAmount(true);
		})
		// Transaction success
		.then((receipt) => {
			fetch('https://api.ridechain.io/user/deduct-rct/'+blockchain.account, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				}
			});
			setBuyRCPSuccess(true);
			setTimeout(() => checkRCAllowance(), 2000);
			setTimeout(() => checkRCPlusAllowance(), 2000);
			setTimeout(() => fetchUserData(), 2000);
			setTimeout(() => fetchRcPlusOrderData(), 2500);
			setTimeout(() => getUserRCBalance(), 3000);
			setTimeout(() => getUserRCPlusBalance(), 4000);
			setTimeout(() => setBuyingRCP(false), 5000);
			setTimeout(() => getRCPPrice(), 6000);	
		});
	};

	const [sellingRCP, setSellingRCP] = useState(false);
	const [sellRCPSuccess, setSellRCPSuccess] = useState(false);
	const sellRCP = () => {
		setSellingRCP(true);
		blockchain.rcPlusHandlerV2.methods
		.sellRCPlus(["0x5b7c9887a800490277b00fe4a30cb149e5cdbbc1", "0x3dE5494f9c61f6dE91b5eB7db0b1CF7c898fF370"], rcPlusBalance, blockchain.web3.utils.toWei((userData.max_earning).toString(), "ether"), false, 0)
		.send({
			gasLimit: 1050000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setSellingRCP(false);
		})
		// Transaction success
		.then((receipt) => {
			var finalDeductAmount;
			if (parseFloat(rcPlusBalance/1000000000000000000*rcpPrice*45/100).toFixed(2) > userData.max_earning) {
				finalDeductAmount = userData.max_earning;
			} else {
				finalDeductAmount = parseFloat(rcPlusBalance/1000000000000000000*rcpPrice*45/100).toFixed(2);
			}
			fetch('https://api.ridechain.io/user/deduct-max-earn/', {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					wallet: blockchain.account,
					final_amount: finalDeductAmount,
				})
			});
			setSellRCPSuccess(true);
			setTimeout(() => checkRCAllowance(), 2000);
			setTimeout(() => checkRCPlusAllowance(), 2000);
			setTimeout(() => fetchUserData(), 2000);
			setTimeout(() => fetchRcPlusOrderData(), 2500);
			setTimeout(() => getUserRCBalance(), 3000);
			setTimeout(() => getUserRCPlusBalance(), 4000);
			setTimeout(() => setSellingRCP(false), 5000);
			setTimeout(() => getRCPPrice(), 6000);	
		});
	};

	const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
	  blockchain.usdtContract.methods.allowance(blockchain.account, "0x089D3B68Bc157c398ee2C062bDed70646C34d77b").call()
	  .then((results) => {
		console.log("OTC Allowance:", results);
		setAllowance(results);
	  });
	};

	const [approving, setApproving] = useState(false);
	const approveToken = () => {
		setApproving(true);
		blockchain.usdtContract.methods
		.approve("0x089D3B68Bc157c398ee2C062bDed70646C34d77b", blockchain.web3.utils.toWei((1000000).toString(), "ether"))
		.send({
			gasLimit: 80000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setApproving(false);
		})
		// Transaction success
		.then((receipt) => {
			setTimeout(() => checkTokenAllowance(), 3000);
			setTimeout(() => setApproving(false), 5000);
		});
	};

	const [rcTradeAmount, setRcTradeAmount] = useState(0);
	const [tradeBalError, setTradeBalError] = useState('');
	const [invalidTradeAmount, setInvalidTradeAmount] = useState(true);
	const handleTradeAmount = (e) => {
	  	const value = e.target.value;
	  	console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidTradeAmount(true);
			setTradeBalError(t('enter-number'));
		} else if (e.target.value < 20) {
			setInvalidTradeAmount(true);
			setTradeBalError(t('min-number'));
		} else if (e.target.value % 10 != 0) {
			setInvalidTradeAmount(true);
			setTradeBalError(t('multiply-error'));
		} else if (rcBalance/1000000000000000000 < e.target.value) {
			setInvalidTradeAmount(true);
			setTradeBalError(t('insufficient-bal'));
		} else {
			setInvalidTradeAmount(false);
			setTradeBalError('');
		}
		setRcTradeAmount(e.target.value);
	};

	const [rctSellAmount, setRctSellAmount] = useState(0);
	const [rctSellError, setRctSellError] = useState('');
	const [invalidRctSellAmount, setInvalidRctSellAmount] = useState(true);
	const handleRctSellAmount = (e) => {
	  	const value = e.target.value;
	  	console.log(!isNaN(+value));
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidRctSellAmount(true);
			setRctSellError(t('enter-number'));
		} else if (e.target.value < 20) {
			setInvalidRctSellAmount(true);
			setRctSellError(t('min-number-20'));
		} else if (e.target.value % 10 != 0) {
			setInvalidRctSellAmount(true);
			setRctSellError(t('multiply-error-20'));
		} else if (userData.rct < e.target.value) {
			setInvalidRctSellAmount(true);
			setRctSellError(t('insufficient-bal'));
		} else {
			setInvalidRctSellAmount(false);
			setRctSellError('');
		}
		setRctSellAmount(e.target.value);
	};

	const [otcBuying, setOtcBuying] = useState(false);
	const [otcBuySuccess, setOtcBuySuccess] = useState(false);
	const otcBuyRC = (_orderID, _amount) => {
		setOtcBuying(true);
		blockchain.otcMarket.methods
		.buyOrder(_orderID, "0x55d398326f99059fF775485246999027B3197955", _amount, false, "0x089D3B68Bc157c398ee2C062bDed70646C34d77b")
		.send({
			gasLimit: 1050000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setOtcBuying(false);
		})
		// Transaction success
		.then((receipt) => {
			fetch('https://api.ridechain.io/order/complete-order', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					wallet: blockchain.account,
					orderID: _orderID,
					rc_transfer_txn: "https://bscscan.com/tx/"+receipt.transactionHash
				})
			});
			setOtcBuySuccess(true);
			setTimeout(() => checkTokenAllowance(), 2000);
			setTimeout(() => getUserUSDTBalance(), 2000);
			setTimeout(() => fetchUserData(), 2000);
			setTimeout(() => fetchAllOrderData(), 3000);
			setTimeout(() => setOtcBuying(false), 5000);
		});
	};

	const otcBuyRCT = (_orderID, _amount) => {
		setOtcBuying(true);
		blockchain.usdtContract.methods
		.transfer("0x687159e4f2786B10C977569Bbb3A0C9c72873EaF", blockchain.web3.utils.toWei((_amount).toString()))
		.send({
			gasLimit: 450000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setOtcBuying(false);
		})
		// Transaction success
		.then((receipt) => {
			fetch('https://api.ridechain.io/order/complete-rct-order', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					wallet: blockchain.account,
					orderID: _orderID
				})
			});
			setOtcBuySuccess(true);
			setTimeout(() => checkTokenAllowance(), 2000);
			setTimeout(() => getUserUSDTBalance(), 2000);
			setTimeout(() => fetchUserData(), 2000);
			setTimeout(() => fetchAllOrderData(), 3000);
			setTimeout(() => setOtcBuying(false), 5000);
		});
	};

	const [rcClaimAmount, setRcClaimAmount] = useState(0);
	const [claimBalError, setClaimBalError] = useState('');
	const [invalidClaimAmount, setInvalidClaimAmount] = useState(true);
	const handleClaimAmount = (e) => {
	  	const value = e.target.value;
	  	console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidClaimAmount(true);
			setClaimBalError(t('enter-number'));
		} else if (e.target.value < 20) {
			setInvalidClaimAmount(true);
			setClaimBalError(t('min-number'));
		} else if (e.target.value % 20 != 0) {
			setInvalidClaimAmount(true);
			setClaimBalError(t('multiply-error-2'));
		} else if (userData.rc < e.target.value) {
			setInvalidClaimAmount(true);
			setClaimBalError(t('insufficient-bal'));
		} else {
			setInvalidClaimAmount(false);
			setClaimBalError('');
		}
		setRcClaimAmount(e.target.value);
	};

	const submitRCClaim = async () => {
		await createRCClaim(userData._id, blockchain.account, rcClaimAmount);
		setTimeout(() => getData(), 2000);
		setTimeout(() => fetchUserData(), 2000);
		setTimeout(() => setClaimSuccess(true), 3000);
	};

	const submitSellOrder = async () => {
		if (otcMarketToken == "RC") {
			setIsOrdering(true);
			blockchain.rcToken.methods
			.transfer("0x674cAddD929A995aa1d6e733Fd96f6Bb5436dd98", blockchain.web3.utils.toWei((rcTradeAmount).toString()))
			.send({
			  gasLimit: 75000,
			  maxPriorityFeePerGas: 3000000000,
			  maxFeePerGas: 3000000000,
			  from: blockchain.account,
			  value: 0,
			})
			// Transaction fail
			.once("error", (err) => {
				setIsOrdering(false);
			})
			// Transaction success
			.then((receipt) => {
				console.log("Sell RC on OTC success. Updating sell order...");
				createSellOrder(userData._id, blockchain.account, rcTradeAmount, "OnchainRC");
				setTimeout(() => getUserRCBalance(), 5000);
			});

		} else if (otcMarketToken == "RCT") {
			await createSellOrder(userData._id, blockchain.account, rctSellAmount, otcMarketToken);
		} else {
			return;
		}
		setTimeout(() => getData(), 5000);
		setTimeout(() => fetchUserData(), 6000);
		setTimeout(() => fetchAllOrderData(), 7000);
		setTimeout(() => fetchUserProcessingOrders(), 8000);
	};

	const [rcSwapAmount, setRcSwapAmount] = useState(0);
	const [swapBalError, setSwapBalError] = useState('');
	const [invalidSwapAmount, setInvalidSwapAmount] = useState(true);
	const handleSwapAmount = (e) => {
		const value = e.target.value;
		console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidSwapAmount(true);
			setSwapBalError('请输入数字');
		} else if (e.target.value < 20) {
			setInvalidSwapAmount(true);
			setSwapBalError('最低数额: 20 RC');
		} else if (e.target.value % 20 != 0) {
			setInvalidSwapAmount(true);
			setSwapBalError('数额必须是 20 的倍数');
		} else if (userData.rc < e.target.value) {
			setInvalidSwapAmount(true);
			setSwapBalError('用户 RC 余额不足');
		} else {
			setInvalidSwapAmount(false);
			setSwapBalError('');
		}
		setRcSwapAmount(e.target.value);
  	};

	  const [buyRCPAmount, setBuyRCPAmount] = useState(0);
	  const [rcpAmountError, setRCPAmountError] = useState('');
	  const [invalidRCPAmount, setInvalidRCPAmount] = useState(true);
	  const handleBuyRCPAmount = (e) => {
		const value = e.target.value;
		console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidRCPAmount(true);
			setRCPAmountError(t('enter-number'));
		} else if (e.target.value < 20) {
			setInvalidRCPAmount(true);
			setRCPAmountError(t('min-number'));
		} else if (e.target.value % 10 != 0) {
			setInvalidRCPAmount(true);
			setRCPAmountError(t('multiply-error'));
		} else if (rcBalance/1000000000000000000  < e.target.value) {
			setInvalidRCPAmount(true);
			setRCPAmountError(t('insufficient-bal'));
		} else if (userData && userData.rct < 20) {
			setInvalidRCPAmount(true);
			setRCPAmountError(t('insufficient-rct'));
		} else {
			setInvalidRCPAmount(false);
			setRCPAmountError('');
		}
		setBuyRCPAmount(e.target.value);
	};

	const [orderData, setOrderData] = useState(null);
	const fetchAllOrderData = async () => {
		const response = await fetch('https://api.ridechain.io/order');
		const json = await response.json();
		if (response.ok) {
			console.log("OTC Market Orders:", json);
			setOrderData(json);
		}
	};

	const [userOrders, setUserOrders] = useState([]);
	const fetchAllUserOrders = async () => {
		const response = await fetch('https://api.ridechain.io/order/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			console.log("User's Orders:", json);
			setUserOrders(json);
		}
	};

	const [userProcessingOrders, setUserProcessingOrders] = useState([]);
	const fetchUserProcessingOrders = async () => {
		const response = await fetch('https://api.ridechain.io/order/rc-processing/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {
			console.log("User's Processing Orders:", json);
			setUserProcessingOrders(json);
		}
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.rideChain !== null) {
			getUserUSDTBalance();
			checkTokenAllowance();
			getUserRCBalance();
			checkRCAllowance();
			checkRCPlusAllowance();
			fetchRcPlusOrderData();
			getUserRCPlusBalance();
			getRCPPrice();
		}
	};
	
	useEffect(() => {
		getData();
		fetchUserData();
		fetchAllOrderData();
		fetchAllUserOrders();
		fetchUserProcessingOrders();
	}, []);

	return (
		<>
		<div className="main-content market-bg">
			<div className="container pt-5">

				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">RC PLUS</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">

								<img className="rc-token-icon" src={RCToken}></img>
								<p className="text-white mt-2 mb-0" style={{fontSize: "24px"}}><strong>${parseFloat(rcpPrice).toFixed(8)}</strong></p>
								<button className="btn custom-btn mt-2" onClick={(e) => {
									window.open("https://www.dextools.io/app/en/token/ridechainplus", "_blank");
								}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('trading-graph')}</strong></h5>
								</button>

								<div className="wallet-balance-box mt-3">
									<p className="text-white my-2">
										<span className="brand-yellow" style={{fontSize:"14px"}}>RC Plus</span>
										<br></br>
										{parseFloat(rcPlusBalance/1000000000000000000).toFixed(2)}
										<br></br>
										<span className="text-gray" style={{fontSize:"14px"}}>≈ {parseFloat(rcPlusBalance/1000000000000000000*rcpPrice).toFixed(2)} RC</span>
									</p>
								</div>

								<div className="row mt-3 mb-3">
									<div className="col-6 pr-2">
										<button className={rcPlusTrade == "Buy" ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
											setRCPlusTrade("Buy");
											fetchUserData();
										}}>
											<h5 className="text-white mt-1 mb-0" style={{fontSize:"18px"}}><strong>{t('buy-btn')}</strong></h5>
										</button>
									</div>
									<div className="col-6 pl-2">
										<button className={rcPlusTrade == "Sell" ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
											setRCPlusTrade("Sell");
											fetchUserData();
										}}>
											<h5 className="text-white mt-1 mb-0" style={{fontSize:"18px"}}><strong>{t('sell-btn')}</strong></h5>
										</button>
									</div>
								</div>
								

								{rcPlusTrade == "Buy" ? (
									<>
										<div className="wallet-balance-box mt-3">
											<p className="text-white my-2">
												<span className="brand-yellow" style={{fontSize:"14px"}}>{t('on-chain')}RC</span>
												<br></br>
												{parseFloat(rcBalance/1000000000000000000).toFixed(2)}
											</p>
											<p className="text-white my-2">
												<span className="brand-yellow" style={{fontSize:"14px"}}>RCT (Min. 20)</span>
												<br></br>
												{userData && parseFloat(userData.rct).toFixed(0)}
											</p>
										</div>

										{userData && userData.total_deposit > 0 ? (
											<>
												{rcAllowance < buyRCPAmount*1000000000000000000 || rcAllowance == 0 ? (
													<>
														{!approvingRC ? (
														<>
															<h5 className="text-white mt-3 mb-0">{t('mining-auth-text')}</h5>
															<p className="text-white mb-0">{t('mining-auth-text2')}</p>
															<button className="btn custom-btn mt-3" onClick={(e) => {
																approveRC();
															}}>
															<img className="w-100" src={CustomBtn}></img>
															<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('mining-auth-btn')}</strong></h5>
															</button>
														</>
														):(
															<p className="brand-yellow mt-2 mb-0" style={{fontSize:"16px"}}>{t('mining-auth-load')}</p>
														)}

													</>
												):(
													<>
														{!buyingRCP ? (
															<>
																{!buyRCPSuccess ? (
																	<>
																		<input
																			type="number"
																			placeholder={t('market-wallet-placeholder')}
																			name='buyRCPAmount'
																			className='form-control text-center mt-3'
																			onChange={handleBuyRCPAmount}
																		/>
																		{rcpAmountError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{rcpAmountError}</p> : <></>}
																		<button disabled={invalidRCPAmount || userData.rct < 20} className="btn custom-btn mt-3" onClick={(e) => {
																			buyRCP(buyRCPAmount);
																		}}>
																			<img className="w-100" src={CustomBtn}></img>
																			<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('swap-btn')}</strong></h5>
																		</button>
																	</>
																):(
																	<>
																		<span style={{fontSize:"36px"}}><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2 mt-3"></i></span>
																		<p className="text-white mt-2 mb-0" style={{fontSize:"18px"}}><strong>{t('buy-rcp-success')}</strong></p>
																		<button className="btn custom-btn mt-3" onClick={(e) => {
																			setBuyRCPSuccess(false);
																			setInvalidRCPAmount(true);
																		}}>
																		<img className="w-100" src={CustomBtn}></img>
																		<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
																		</button>
																	</>
																)}
															</>
														):(
															<>
																<p className="brand-yellow mt-2 mb-0" style={{fontSize:"14px"}}>{t('buying-rcp')}</p>
															</>
														)}
													</>
												)}
											</>
										):(
											<>
												<p className="brand-yellow mt-3">{t('mining-required')}</p>
											</>
										)}

									</>
								):(
									<></>
								)}




								{rcPlusTrade == "Sell" ? (
									<>
								
										<div className="wallet-balance-box mt-3">
											<p className="text-white my-2">
												<span className="brand-yellow" style={{fontSize:"14px"}}>RC Plus</span>
												<br></br>
												{parseFloat(rcPlusBalance/1000000000000000000).toFixed(2)}
											</p>
											<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
											<p className="text-white mt-2 mb-0">
												<span className="brand-yellow" style={{fontSize:"14px"}}>RC</span>
												<br></br>
												≈ {parseFloat(rcPlusBalance/1000000000000000000*rcpPrice*45/100).toFixed(2)}
											</p>
											<p className="text-white mt-0">
												<span className="error" style={{fontSize:"14px"}}><strong>-50% Burn LP</strong></span>
											</p>
											<hr className="divider"></hr>
											<p className="text-white my-2">
												<span className="brand-yellow" style={{fontSize:"14px"}}>{t('profile-profit')}</span>
												<br></br>
												{parseFloat(userData && userData.max_earning).toFixed(2)} RC
											</p>
										</div>

										
										{userData && userData.total_deposit > 0 ? (
											<>
												{Number(rcPlusAllowance/1000000000000000000) <= Number(rcPlusBalance/1000000000000000000) ? (
													<>
														{!approvingRCPlus ? (
														<>
															<h5 className="text-white mt-3 mb-0">{t('mining-auth-text')}</h5>
															<p className="text-white mb-0">{t('mining-auth-text2')}</p>
															<button className="btn custom-btn mt-3" onClick={(e) => {
																approveRCPlus();
															}}>
															<img className="w-100" src={CustomBtn}></img>
															<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('mining-auth-btn')}</strong></h5>
															</button>
														</>
														):(
															<p className="brand-yellow mt-2 mb-0" style={{fontSize:"16px"}}>{t('mining-auth-load')}</p>
														)}

													</>
												):(
													<>
														{!sellingRCP ? (
															<>
																{!sellRCPSuccess ? (
																	<>
																		{/* {rcPlusAllowance < rcPlusBalance ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{rcpAmountError}</p> : <></>} */}
																		<button disabled={rcPlusBalance <= 0 || userData && userData.max_earning <= 0} className="btn custom-btn mt-3" onClick={(e) => {
																			sellRCP();
																		}}>
																			<img className="w-100" src={CustomBtn}></img>
																			<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('sell-btn')}</strong></h5>
																		</button>
																	</>
																):(
																	<>
																		<span style={{fontSize:"36px"}}><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2 mt-3"></i></span>
																		<p className="text-white mt-2 mb-0" style={{fontSize:"18px"}}><strong>{t('buy-rcp-success')}</strong></p>
																		<button className="btn custom-btn mt-3" onClick={(e) => {
																			setSellRCPSuccess(false);
																		}}>
																		<img className="w-100" src={CustomBtn}></img>
																		<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
																		</button>
																	</>
																)}
															</>
														):(
															<>
																<p className="brand-yellow mt-2 mb-0" style={{fontSize:"14px"}}>{t('buying-rcp')}</p>
															</>
														)}
													</>
												)}
											</>
										):(
											<>
												<p className="brand-yellow mt-3">{t('mining-required')}</p>
											</>
										)}

									</>
								):(
									<></>
								)}



						</div>
					</div>
				</div>

				{/* Claim RC Token */}
				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('claim-title')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							{isOrdering ? (
								<div className="w-100 text-center mt-3">
									<h5 className="brand-yellow"><strong>{t('market-swap-loading1')}</strong></h5>
									<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-loading2')}</strong></p>
								</div>
							):(
								<div className="w-100 text-center mt-3">
									{!claimSuccess ? (
										<>
											<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">{t('market-wallet')}:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
											<input
												type="number"
												placeholder={t('market-wallet-placeholder')}
												name='swapAmount'
												className='form-control text-center'
												onChange={handleClaimAmount}
											/>
											{claimBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{claimBalError}</p> : <></>}
											<p className="error mb-0 mt-3" style={{fontSize:"24px"}}><strong>-{parseFloat(Number(rcClaimAmount)).toFixed(2)} <span className="text-white">RC</span></strong></p>
											<div className="w-100 text-center my-3">
												<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
											</div>
											<p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(rcClaimAmount).toFixed(2)} <span className="text-white">{t('on-chain')}RC</span></strong></p>
											<button disabled={invalidClaimAmount} className="btn custom-btn mt-3" onClick={(e) => {
												submitRCClaim();
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('claim-btn')}</strong></h5>
											</button>
										</>
									):(
										<>
											<h5 className="profit-green"><strong>{t('claim-rc-notice')}</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('claim-rc-notice-desc')}</strong></p>
											<button className="btn custom-btn mt-3" onClick={(e) => {
												setRcClaimAmount(0);
												setClaimSuccess(false);
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
											</button>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				
				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('my-wallet')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box">
							<div className="wallet-balance-box text-left">
								<p className="text-white mb-0"><span className="brand-yellow">$USDT:</span> {parseFloat(usdtBalance/1000000000000000000).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">{t('on-chain')}RC:</span> {parseFloat(rcBalance/1000000000000000000 || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">RC:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">WRC:</span> {userData && parseFloat(userData.wrc || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">SRC:</span> {userData && parseFloat(userData.src || 0).toFixed(2)}</p>
							</div>
							<div className="wallet-balance-box text-left mt-3">
								<p className="text-white mb-0"><span className="brand-yellow">RCT:</span> {userData && parseFloat(userData.rct || 0).toFixed(2)}</p>
							</div>
						</div>
					</div>
				</div>

				{/* WRC  */}
				{/* <div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('market-title2')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							<p className="text-white mb-2" style={{fontSize:"14px"}}><span className="brand-yellow">{t('market-wallet')}:</span> {userData && parseFloat(userData.rc || 0).toFixed(2)}</p>
							<input
								type="number"
								placeholder={t('market-wallet-placeholder')}
								name='swapAmount'
								className='form-control text-center'
								onChange={handleSwapAmount}
							/>
							{swapBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{swapBalError}</p> : <></>}
							<p className="text-white mt-2" style={{fontSize:"12px"}}>*{t('market-wallet-desc')}<span className="brand-green">+5%</span>WRC</p>
							<div className="w-100 text-center mt-5">
								<p className="error mb-0" style={{fontSize:"24px"}}><strong>-{parseFloat(Number(rcSwapAmount)).toFixed(2)} <span className="text-white">RC</span></strong></p>
								<div className="w-100 text-center my-3">
									<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
								</div>
								<p className="text-white mb-0" style={{fontSize:"12px"}}>{t('market-swap-desc')}: <span className="profit-green">+5%</span></p>
								<p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(Number(rcSwapAmount)+Number(rcSwapAmount*5/100)).toFixed(2)} <span className="text-white">WRC</span></strong></p>
								<button disabled className="btn custom-btn mt-3" onClick={(e) => {
								}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('coming-soon')}</strong></h5>
								</button>
							</div>
						</div>
					</div>
				</div> */}


				{/* Sell on OTC Market */}
				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('market-title3')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="market-box text-center">
							<div className="row mb-5">
								<div className="col-6 pr-2">
									<button className={otcMarketToken == "RC" ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
										setOtcMarketToken("RC");
										setRcTradeAmount(0);
										setInvalidTradeAmount(true);
									}}>
										<h5 className="text-white mt-1 mb-0" style={{fontSize:"18px"}}><strong>{t('on-chain')}RC</strong></h5>
									</button>
								</div>
								<div className="col-6 pl-2">
									<button className={otcMarketToken == "RCT" ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
										setOtcMarketToken("RCT");
										setRctSellAmount(0);
										setInvalidRctSellAmount(true);
									}}>
										<h5 className="text-white mt-1 mb-0" style={{fontSize:"18px"}}><strong>RCT</strong></h5>
									</button>
								</div>
							</div>


							{otcMarketToken == "RC" ? (
								<>
									{isOrdering ? (
										<div className="w-100 text-center mt-3">
											<h5 className="brand-yellow"><strong>{t('market-swap-loading1')}</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-loading2')}</strong></p>
										</div>
									):(
										<div className="w-100 text-center mt-3">
											{!orderSuccess ? (
												<>
													{userProcessingOrders && userProcessingOrders.length < 3 ? (
														<>
															<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">{t('on-chain')}{t('market-wallet')}:</span> {userData && parseFloat(rcBalance/1000000000000000000 || 0).toFixed(2)}</p>
															<input
																type="number"
																placeholder={t('market-wallet-placeholder')}
																name='swapAmount'
																className='form-control text-center'
																onChange={handleTradeAmount}
															/>
															{tradeBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{tradeBalError}</p> : <></>}
															<p className="error mb-0 mt-5" style={{fontSize:"24px"}}><strong>-{parseFloat(Number(rcTradeAmount)).toFixed(2)} <span className="text-white">{t('on-chain')}RC</span></strong></p>
															<div className="w-100 text-center my-3">
																<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
															</div>
															<p className="text-white mb-0" style={{fontSize:"12px"}}>{t('market-fees')}: <span className="brand-yellow">3%</span></p>
															<p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(rcTradeAmount-(rcTradeAmount*3/100)).toFixed(2)} <span className="text-white">USDT</span></strong></p>
															{/* <button disabled={invalidTradeAmount} className="btn custom-btn mt-3" onClick={(e) => {
																submitSellOrder();
															}}>
																<img className="w-100" src={CustomBtn}></img>
																<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('otc-sell-btn')}</strong></h5>
															</button> */}
															<button disabled className="btn custom-btn mt-3" onClick={(e) => {
															}}>
																<img className="w-100" src={CustomBtn}></img>
																<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('maintenance')}</strong></h5>
															</button>
														</>
													):(
														<>
															<p className="brand-yellow mb-0">{t('listing-limit-reached')}</p>
														</>
													)}

												</>
											):(
												<>
													<h5 className="profit-green"><strong>{t('market-swap-notice')}</strong></h5>
													<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-notice-desc')}</strong></p>
													<button className="btn custom-btn mt-3" onClick={(e) => {
														setOrderSuccess(false);
														fetchAllOrderData();
														fetchAllUserOrders();
														fetchUserProcessingOrders();
													}}>
														<img className="w-100" src={CustomBtn}></img>
														<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
													</button>
												</>
											)}
										</div>
									)}
									{userProcessingOrders && userProcessingOrders.length > 0 || userProcessingOrders.length < 3 ? (
										<div className="col-12 mt-3">
											<>	
												{userProcessingOrders && userProcessingOrders.length > 0 ? <h5 className="brand-blue mt-5 mb-2">{t('my-listing')}</h5> : <></>}
												
												{userProcessingOrders && userProcessingOrders.map((processingOrder, index) => (
													<>
														<div className="">
															<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>OTC #{index+1}: <span className="text-white mb-0">{parseFloat(processingOrder.rc_amount).toFixed(0)} RC<i className="fa-solid fa-arrow-right mx-2"></i></span><span className="profit-green">{parseFloat(processingOrder.usdt_amount).toFixed(2)} USDT</span></strong></p>
															<p className="brand-yellow" style={{fontSize:"14px"}}>{t('listing-otc')}</p>
														</div>
													</>
												))}
											</>
										</div>
									):(
										<></>
									)}
								</>
							):(
								<>

								</>
							)}


							{otcMarketToken == "RCT" ? (
								<>
									{isOrdering ? (
										<div className="w-100 text-center mt-3">
											<h5 className="brand-yellow"><strong>{t('otc-selling-rct')}</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-loading2')}</strong></p>
										</div>
									):(
										<div className="w-100 text-center mt-3">
											{!orderSuccess ? (
												<>
													<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">{t('enter-rct-amount')}:</span> {userData && parseFloat(userData.rct || 0).toFixed(2)}</p>
													<input
														type="number"
														placeholder={t('enter-rct-amount')}
														name='swapAmount'
														className='form-control text-center'
														onChange={handleRctSellAmount}
													/>
													{rctSellError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{rctSellError}</p> : <></>}
													<p className="error mb-0 mt-5" style={{fontSize:"24px"}}><strong>-{parseFloat(Number(rctSellAmount)).toFixed(2)} <span className="text-white">RCT</span></strong></p>
													<div className="w-100 text-center my-3">
														<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
													</div>
													<p className="text-white mb-0" style={{fontSize:"12px"}}>{t('market-fees')}: <span className="brand-yellow">3%</span></p>
													<p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(rctSellAmount-(rctSellAmount*3/100)).toFixed(2)} <span className="text-white">USDT</span></strong></p>
													{/* <button disabled={invalidRctSellAmount} className="btn custom-btn mt-3" onClick={(e) => {
														submitSellOrder();
													}}>
														<img className="w-100" src={CustomBtn}></img>
														<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('otc-sell-btn')}</strong></h5>
													</button> */}
													<button disabled className="btn custom-btn mt-3" onClick={(e) => {
													}}>
														<img className="w-100" src={CustomBtn}></img>
														<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('maintenance')}</strong></h5>
													</button>
												</>
											):(
												<>
													<h5 className="profit-green"><strong>{t('otc-sell-rct-success')}</strong></h5>
													<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('otc-sell-rct-desc')}</strong></p>
													<button className="btn custom-btn mt-3" onClick={(e) => {
														setOrderSuccess(false);
													}}>
														<img className="w-100" src={CustomBtn}></img>
														<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
													</button>
												</>
											)}
										</div>
									)}
								</>
							):(
								<></>
							)}

						</div>
					</div>
				</div>


				{/* OTC Market */}
				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('market-title4')}</h3>
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-6 pr-2">
						<button className={otcMarketToken == "RC" ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
							setOtcMarketToken("RC");
							setRcTradeAmount(0);
							setInvalidTradeAmount(true);
						}}>
							<h5 className="text-white mt-1 mb-0" style={{fontSize:"18px"}}><strong>{t('on-chain')}RC</strong></h5>
						</button>
					</div>
					<div className="col-6 pl-2">
						<button className={otcMarketToken == "RCT" ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
							setOtcMarketToken("RCT");
							setRctSellAmount(0);
							setInvalidRctSellAmount(true);
						}}>
							<h5 className="text-white mt-1 mb-0" style={{fontSize:"18px"}}><strong>RCT</strong></h5>
						</button>
					</div>
				</div>
				
				{otcMarketToken == "RC" ? (
					<div className="row">
						{orderData && orderData.length > 0 ? (
							<div className="col-12 mt-2">
								{allowance == 0 || allowance/1000000000000000000 < 10000 ? (
									<>
										{!approving ? (
											<div className="data-box mt-2 text-center">
												<h5 className="brand-blue mt-3 mb-0">{t('mining-auth-btn')}OTC</h5>
												<p className="text-white mt-2 mb-0">{t('mining-auth-text2')}</p>
												<button className="btn custom-btn mt-3" onClick={(e) => {
													approveToken();
												}}>
													<img className="w-100" src={CustomBtn}></img>
													<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('mining-auth-btn')}</strong></h5>
												</button>
											</div>
										):(
											<div className="data-box mt-2 text-center">
												<h5 className="brand-blue mt-3 mb-0">{t('mining-auth-btn')}OTC</h5>
												<p className="brand-yellow mt-2 mb-0" style={{fontSize:"16px"}}>{t('mining-auth-load')}</p>
											</div>
										)}
									</>
								):(
									<>
										{orderData && orderData.map((marketOrder, index) => (
											<>
												{marketOrder.token == "OnchainRC" && marketOrder.status == "Processing" ? (
													<div className="data-box mt-2" key={index}>
														<p className="text-white mb-0" style={{fontSize:"12px"}}>{Moment(marketOrder.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
														<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>{t('seller')}：</strong><span className="text-white mb-0" style={{fontSize:"12px"}}>{"0x..."+marketOrder.seller_wallet.substring(36)}</span></p>
														<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{parseFloat(marketOrder.rc_amount).toFixed(0)} USDT<i className="fa-solid fa-arrow-right mx-2"></i>{parseFloat(marketOrder.rc_amount).toFixed(0)} RC</strong></p>
														{!otcBuying ? (
															<>
																{usdtBalance/1000000000000000000 < marketOrder.rc_amount ? <p className="error mb-0" style={{fontSize:"12px"}}>{t('usdt-insufficient')}</p> : <></>}
																<button disabled={usdtBalance/1000000000000000000 < marketOrder.rc_amount || allowance/1000000000000000000 < marketOrder.rc_amount} className="btn btn-primary mt-2 mx-0" onClick={(e) => {
																	otcBuyRC(marketOrder._id, marketOrder.rc_amount);
																}}>
																	<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('buy-btn')}</strong></p>
																</button>
															</>
														):(
															<>
																<p className="brand-yellow mb-0" style={{fontSize:"14px"}}>{t('otc-buying')}</p>
															</>
														)}
													</div>
												):(
													<></>
												)}
											</>
										))}
									</>
								)}
							</div>
						):(
							<div className="col-12 mt-2">
								<div className="data-box text-center">
									<p className="text-gray mb-0">{t('market-history-none')}</p>
								</div>
							</div>
						)}
					</div>
				):(
					<></>
				)}

				{otcMarketToken == "RCT" ? (
					<div className="row">
						{orderData && orderData.length > 0 ? (
							<div className="col-12 mt-2">
								{allowance == 0 || allowance/1000000000000000000 < 10000 ? (
									<>
										{!approving ? (
											<div className="data-box mt-2 text-center">
												<h5 className="brand-blue mt-3 mb-0">{t('mining-auth-btn')}OTC</h5>
												<p className="text-white mt-2 mb-0">{t('mining-auth-text2')}</p>
												<button className="btn custom-btn mt-3" onClick={(e) => {
													approveToken();
												}}>
													<img className="w-100" src={CustomBtn}></img>
													<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('mining-auth-btn')}</strong></h5>
												</button>
											</div>
										):(
											<div className="data-box mt-2 text-center">
												<h5 className="brand-blue mt-3 mb-0">{t('mining-auth-btn')}OTC</h5>
												<p className="brand-yellow mt-2 mb-0" style={{fontSize:"16px"}}>{t('mining-auth-load')}</p>
											</div>
										)}
									</>
								):(
									<>
										{orderData && orderData.map((marketOrder, index) => (
											<>
												{marketOrder.token == "RCT" && marketOrder.status == "Processing" ? (
													<div className="data-box mt-2" key={index}>
														<p className="text-white mb-0" style={{fontSize:"12px"}}>{Moment(marketOrder.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
														<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>{t('seller')}：</strong><span className="text-white mb-0" style={{fontSize:"12px"}}>{"0x..."+marketOrder.seller_wallet.substring(36)}</span></p>
														<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{parseFloat(marketOrder.rc_amount).toFixed(0)} USDT<i className="fa-solid fa-arrow-right mx-2"></i>{parseFloat(marketOrder.rc_amount).toFixed(0)} RCT</strong></p>
														{!otcBuying ? (
															<>
																{usdtBalance/1000000000000000000 < marketOrder.rc_amount ? <p className="error mb-0" style={{fontSize:"12px"}}>{t('usdt-insufficient')}</p> : <></>}
																<button disabled={usdtBalance/1000000000000000000 < marketOrder.rc_amount || allowance/1000000000000000000 < marketOrder.rc_amount} className="btn btn-primary mt-2 mx-0" onClick={(e) => {
																	otcBuyRCT(marketOrder._id, marketOrder.rc_amount);
																}}>
																	<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('buy-btn')}</strong></p>
																</button>
															</>
														):(
															<>
																<p className="brand-yellow mb-0" style={{fontSize:"14px"}}>{t('otc-buying')}</p>
															</>
														)}
													</div>
												):(
													<></>
												)}
											</>
										))}
									</>
								)}
							</div>
						):(
							<div className="col-12 mt-2">
								<div className="data-box text-center">
									<p className="text-gray mb-0">{t('market-history-none')}</p>
								</div>
							</div>
						)}
					</div>
				):(
					<></>
				)}

			</div>
		</div>
		</>
	);

};

export default MarketPage;