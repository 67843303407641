import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { setLanguage, t } from '../translation.js';

import CustomBtn from '../assets/img/custom-btn.png';
import RCToken from '../assets/img/rcc-token.png';
import RCCPrivateSale from '../assets/img/rcc-private-sale.jpg';

import { useStake } from '../hooks/useStake';

const StakingPage = () => {

	const { isStaking, setIsStaking, stakeSuccess, setStakeSuccess, createStakeRC, createStakeRCC } = useStake();

	const blockchain = useSelector((state) => state.blockchain);

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
		setCopied(true);
		setCopyText("复制成功");
		setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
		blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
		.then((results) => {
			console.log("USDT Balance:", results);
			setUSDTBalance(results);
		});
	};

	const [userStakingData, setUserStakingData] = useState(null);
	const fetchUserStakingData = async () => {
	  const response = await fetch('https://api.ridechain.io/staking/wallet/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		console.log("User Staking Data:", json);
		setUserStakingData(json);
	  }
	};

	const [rcBalance, setRCBalance] = useState(0);
	const getUserRCBalance = () => {
		blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x3dE5494f9c61f6dE91b5eB7db0b1CF7c898fF370").call()
		.then((results) => {
		console.log("RC Balance:", results);
		setRCBalance(results);
		});
	};

	const [rccBalance, setRCCBalance] = useState(0);
	const getUserRCCBalance = () => {
		blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0xE9A4790e8e96dDEfDd54C8dfe8DDc66983673438").call()
		.then((results) => {
		console.log("RCC Balance:", results);
		setRCCBalance(results);
		});
	};

	const [rcAllowance, setRCAllowance] = useState(0);
	const checkRCAllowance = () => {
		blockchain.rcToken.methods.allowance(blockchain.account, "0x43aC236E152d48EC667C15ccB552f8038eC753f7").call()
		.then((results) => {
		console.log("RC Allowance:", results);
		setRCAllowance(results);
		});
	};

	const [stakeRCDays, setStakeRCDays] = useState(90);
	const [stakeRCAmount, setStakeRCAmount] = useState(0);
	const [stakeRCBalError, setStakeRCBalError] = useState('');
	const [invalidStakeRCAmount, setInvalidStakeRCAmount] = useState(true);
	const handleStakeRCAmount = (e) => {
		const value = e.target.value;
		console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidStakeRCAmount(true);
			setStakeRCBalError(t('enter-number'));
		} else if (e.target.value < 20) {
			setInvalidStakeRCAmount(true);
			setStakeRCBalError(t('min-number'));
		} else if (e.target.value % 20 != 0) {
			setInvalidStakeRCAmount(true);
			setStakeRCBalError(t('multiply-error-2'));
		} else if (rcBalance/1000000000000000000 < e.target.value) {
			setInvalidStakeRCAmount(true);
			setStakeRCBalError(t('insufficient-bal'));
		} else {
			setInvalidStakeRCAmount(false);
			setStakeRCBalError('');
		}
		setStakeRCAmount(e.target.value);
	};

	const submitStakeRC = async () => {
		setIsStaking(true);
		console.log("RC to stake:", stakeRCAmount);
		blockchain.rcToken.methods
		.transfer("0x70b3541a9a0a6c823e4D79Bc6FfC7198bC38CA18", blockchain.web3.utils.toWei((stakeRCAmount).toString()))
		.send({
			gasLimit: 75000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setIsStaking(false);
		})
		// Transaction success
		.then((receipt) => {
			console.log("Stake RC success.");
			setStakeSuccess(true);
			fetch('https://api.ridechain.io/staking/create', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					wallet: blockchain.account,
					token: "RC",
					amount: Number(stakeRCAmount),
					lock_days: stakeRCDays,
					txn_link: "https://bscscan.com/tx/"+receipt.transactionHash
				})
			});

			setTimeout(() => getUserRCBalance(), 3000);
			setTimeout(() => fetchUserStakingData(), 4000);
			setTimeout(() => getData(), 4000);
			setTimeout(() => setIsStaking(false), 5000);

		});
	};

	const [stakeRCCDays, setStakeRCCDays] = useState(60);
	const [stakeRCCAmount, setStakeRCCAmount] = useState(0);
	const [stakeRCCBalError, setStakeRCCBalError] = useState('');
	const [invalidStakeRCCAmount, setInvalidStakeRCCAmount] = useState(true);
	const handleStakeRCCAmount = (e) => {
		const value = e.target.value;
		console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidStakeRCCAmount(true);
			setStakeRCCBalError(t('enter-number'));
		} else if (e.target.value < 100) {
			setInvalidStakeRCCAmount(true);
			setStakeRCCBalError(t('min-rcc-stake'));
		} else if (e.target.value % 10 != 0) {
			setInvalidStakeRCCAmount(true);
			setStakeRCCBalError(t('rcc-stake-multiply'));
		} else if (rccBalance/1000000 < e.target.value) {
			setInvalidStakeRCCAmount(true);
			setStakeRCCBalError(t('rcc-insufficient-bal'));
		} else {
			setInvalidStakeRCCAmount(false);
			setStakeRCCBalError('');
		}
		setStakeRCCAmount(e.target.value);
	};

	const submitStakeRCC = async () => {
		setIsStaking(true);
		console.log("RCC to stake:", stakeRCCAmount);
		blockchain.rccToken.methods
		.transfer("0x70b3541a9a0a6c823e4D79Bc6FfC7198bC38CA18", stakeRCCAmount*1000000)
		.send({
			gasLimit: 75000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setIsStaking(false);
			setStakeRCCAmount(0);
		})
		// Transaction success
		.then((receipt) => {
			console.log("Stake RCC success.");
			setStakeSuccess(true);
			fetch('https://api.ridechain.io/staking/create', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					wallet: blockchain.account,
					token: "RCC",
					amount: Number(stakeRCCAmount),
					lock_days: stakeRCCDays,
					txn_link: "https://bscscan.com/tx/"+receipt.transactionHash
				})
			});

			setTimeout(() => getUserRCCBalance(), 3000);
			setTimeout(() => fetchUserStakingData(), 4000);
			setTimeout(() => getData(), 4000);
			setTimeout(() => setIsStaking(false), 5000);

		});
	};

	const [superstakeRCCBalError, setSuperstakeRCCBalError] = useState('');
	const submitSuperStakeRCC = async () => {
		setIsStaking(true);
		console.log("RCC to super stake:", superstakeRCCAmount);
		if (rccBalance < superstakeRCCAmount*1000000) {
			setIsStaking(false);
			setSuperstakeRCCBalError(t('rcc-insufficient-bal'));
			return;
		}
		blockchain.rccToken.methods
		.transfer("0x70b3541a9a0a6c823e4D79Bc6FfC7198bC38CA18", superstakeRCCAmount*1000000)
		.send({
			gasLimit: 75000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setIsStaking(false);
			setSuperstakeRCCAmount(0);
		})
		// Transaction success
		.then((receipt) => {
			console.log("Stake RCC success.");
			setStakeSuccess(true);
			fetch('https://api.ridechain.io/staking/create', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					wallet: blockchain.account,
					token: "SuperRCC",
					amount: Number(superstakeRCCAmount*1.5),
					lock_days: Number(90),
					txn_link: "https://bscscan.com/tx/"+receipt.transactionHash
				})
			});

			setTimeout(() => getUserRCCBalance(), 3000);
			setTimeout(() => fetchUserStakingData(), 4000);
			setTimeout(() => getData(), 4000);
			setTimeout(() => setIsStaking(false), 5000);

		});
	};

	const [privateSaleAmount, setPrivateSaleAmount] = useState(0);
	const [privateSaleAmountError, setPrivateSaleAmountError] = useState('');
	const [invalidPrivateSaleAmount, setInvalidPrivateSaleAmount] = useState(true);
	const handlePrivateSaleAmount = (e) => {
		const value = e.target.value;
		console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
		if (isNaN(+value)) {
			setInvalidPrivateSaleAmount(true);
			setPrivateSaleAmountError(t('enter-number'));
		} else if (e.target.value < 100) {
			setInvalidPrivateSaleAmount(true);
			setPrivateSaleAmountError(t('min-private-sale-amount'));
		} else if (e.target.value > 30000) {
			setInvalidPrivateSaleAmount(true);
			setPrivateSaleAmountError(t('max-private-sale-amount'));
		} else if (e.target.value % 10 != 0) {
			setInvalidPrivateSaleAmount(true);
			setPrivateSaleAmountError(t('private-sale-amount-multiplier'));
		// } else if (usdtBalance/1000000000000000000 < e.target.value) {
		// 	setInvalidPrivateSaleAmount(true);
		// 	setPrivateSaleAmountError(t('insufficient-usdt'));
		} else {
			setInvalidPrivateSaleAmount(false);
			setPrivateSaleAmountError('');
		}
		setPrivateSaleAmount(e.target.value);
	};

	const [superstakeRCCAmount, setSuperstakeRCCAmount] = useState(300);

	const getData = () => {
		if (blockchain.account !== "" && blockchain.rideChain !== null) {
			getUserRCBalance();
			getUserRCCBalance();
			checkRCAllowance();
			getUserUSDTBalance();
		}
	};

	const [launchComingSoon, setLaunchComingSoon] = useState(true);

	const [saleStart, setSaleStart] = useState(false);
	const [soldOut, setSoldOut] = useState(false);

	useEffect(() => {
		getData();
		fetchUserData();
		fetchUserStakingData();
	}, []);

	return (
		<>

		<div class="modal fade" id="rccPrivateSaleModal" tabindex="-1" data-keyboard="false" aria-labelledby="rccPrivateSaleLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content invest-modal py-3">
					<div class="modal-body text-center px-3">
						<img className="w-100" src={RCCPrivateSale}></img>

						<h5 className="brand-blue mt-3">{t('bnb-price')}:</h5>
						<p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(privateSaleAmount/600).toFixed(2)} BNB</strong></p>

						<h5 className="brand-blue mt-3">{t('payment-address')}:</h5>
						<MiddleEllipsis><span className="text-white mt-1 mb-0" style={{fontSize:"14px"}}>0xDe0Ab2e10048d6Aa99dD4a59828Baa9287D55DDa</span></MiddleEllipsis>

						<CopyToClipboard 
							text={"0xDe0Ab2e10048d6Aa99dD4a59828Baa9287D55DDa"}
							onCopy={() => copyReferral()}
						>
							<button className="btn custom-btn mb-3" onClick={(e) => {
							}}>
								<img className="w-100" src={CustomBtn}></img>
								{!copied ? (
									<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('copy-address')}</strong></h5>
								):(
									<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('copied-address')}</strong></h5>
								)}
							</button>
						</CopyToClipboard>
						<hr className="divider"></hr>
						<button className="btn custom-btn mt-2" data-dismiss="modal" aria-label="Close" onClick={(e) => {
						}}>
							<img className="w-100" src={CustomBtn}></img>
							<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-payment')}</strong></h5>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div className="main-content market-bg">
			<div className="container pt-5">

				{launchComingSoon ? (
					<>
						<div className="row">
							<div className="col-12 text-center">
								<h3 className="brand-blue">{t('launching-soon')}</h3>
							</div>
						</div>

						<div className="row">
							<div className="col-12 text-center">
								<FlipCountdown
									size='small' // Options (Default: medium): large, medium, small, extra-small.
									theme='dark'
									hideYear
									endAtZero
									onTimeUp={() => setLaunchComingSoon(false)}
									endAt={1744070400000} // Date/Time
								/>
							</div>
						</div>
					</>
				):(
					<></>
				)}

				<div className="row">
					<div className="col-12 mt-5 text-center">
						<h3 className="brand-blue">{t('rcc-token')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">

							<h5 className="brand-blue mb-3">{t('private-sale')}</h5>
							<img className="rc-token-icon" src={RCToken}></img>
							<p className="text-white mt-2 mb-0" style={{fontSize: "24px"}}><strong>${parseFloat(0.8).toFixed(2)} USDT</strong></p>

							{!soldOut ? (
								<p className="brand-blue">315,000 RCC</p>
							):(
								<>
									{/* <p className="brand-blue mb-0">250000 / 250000 RCC</p> */}
									<p className="brand-yellow mb-0">{t('sale-ended')}</p>
								</>
							)}

							<div className="d-none">
								<FlipCountdown
									size='small' // Options (Default: medium): large, medium, small, extra-small.
									theme='dark'
									hideYear
									hideMonth
									endAtZero
									onTimeUp={() => setSoldOut(true)}
									endAt={1743413580000} // Date/Time
								/>
							</div>

							<hr className="divider"></hr>

							<div className="text-left mb-3">
								<p className="brand-yellow mb-0">{t('sale-start-time')}: <span className="text-white">2025-03-31 15:33H</span></p>
								<p className="brand-yellow mb-0">{t('sale-end-time')}: <span className="text-white">2025-04-06 15:33H</span></p>
								<h5 className="brand-blue mt-3">{t('release-terms-title')}:</h5>
								<p className="text-white mb-0">1) {t('release-terms-1')}</p>
								<p className="text-white mb-0">2) {t('release-terms-2')}</p>
								<p className="text-white mb-0">3) {t('release-terms-3')}</p>
								<p className="text-white mb-0">4) {t('release-terms-4')}</p>
								<p className="text-white mb-0">5) {t('release-terms-5')}</p>
								<p className="text-white mb-0">6) {t('release-terms-6')}</p>
								<h5 className="brand-blue mt-3">{t('private-sale-title')}:</h5>
								<p className="text-white mb-0">{t('private-sale-details')}</p>
								<input
									type="number"
									placeholder={t('private-sale-amount-placeholder')}
									name='swapAmount'
									className='form-control mt-2'
									onChange={handlePrivateSaleAmount}
								/>
								{privateSaleAmountError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{privateSaleAmountError}</p> : <></>}
								<h5 className="brand-blue mt-3">{t('bnb-price')}:</h5>
								<p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(privateSaleAmount/600).toFixed(2)} BNB</strong></p>
							</div>

							{!saleStart ? (
								<FlipCountdown
									size='small'
									theme='dark'
									hideYear
									hideMonth
									endAtZero
									onTimeUp={() => setSaleStart(true)}
									endAt={1743406380000}
								/>
							):(
								<>
									{soldOut ? (
										<button disabled className="btn custom-btn mt-2" onClick={(e) => {
										}}>
											<img className="w-100" src={CustomBtn}></img>
											<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('sale-ended')}</strong></h5>
										</button>
									):(
										<button disabled={privateSaleAmount/600 <= 0 || soldOut} className="btn custom-btn mt-2" data-toggle="modal" data-target="#rccPrivateSaleModal" data-backdrop="static" onClick={(e) => {
										}}>
											<img className="w-100" src={CustomBtn}></img>
											<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('buy-now')}</strong></h5>
										</button>
									)}

								</>
							)}

				
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('superstake-rcc-title')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							{isStaking ? (
								<div className="w-100 text-center mt-3">
									<h5 className="brand-yellow"><strong>{t('stake-processing')}</strong></h5>
									<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-loading2')}</strong></p>
								</div>
							):(
								<div className="w-100 text-center mt-3">
									{!stakeSuccess ? (
										<>
											<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">{t('rcc-balance')}:</span> {userData && parseFloat(rccBalance/1000000).toFixed(2)}</p>

											<div className="row mb-3">
												<div className="col-6 pr-2">
													<button className={superstakeRCCAmount == 300 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setSuperstakeRCCBalError('');
														setSuperstakeRCCAmount(300);
														fetchUserData();
														if (rccBalance < 300*1000000) {
															setSuperstakeRCCBalError(t('rcc-insufficient-bal'));
														}
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>300 RCC</strong></p>
													</button>
												</div>
												<div className="col-6 pl-2">
													<button className={superstakeRCCAmount == 1000 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setSuperstakeRCCBalError('');
														setSuperstakeRCCAmount(1000);
														fetchUserData();
														if (rccBalance < 1000*1000000) {
															setSuperstakeRCCBalError(t('rcc-insufficient-bal'));
														}
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>1000 RCC</strong></p>
													</button>
												</div>
												<div className="col-6 pr-2">
													<button className={superstakeRCCAmount == 3000 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setSuperstakeRCCBalError('');
														setSuperstakeRCCAmount(3000);
														fetchUserData();
														if (rccBalance < 3000*1000000) {
															setSuperstakeRCCBalError(t('rcc-insufficient-bal'));
														}
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>3000 RCC</strong></p>
													</button>
												</div>
												<div className="col-6 pl-2">
													<button className={superstakeRCCAmount == 5000 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setSuperstakeRCCBalError('');
														setSuperstakeRCCAmount(5000);
														fetchUserData();
														if (rccBalance < 5000*1000000) {
															setSuperstakeRCCBalError(t('rcc-insufficient-bal'));
														}
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>5000 RCC</strong></p>
													</button>
												</div>
												<div className="col-12">
													<button className={superstakeRCCAmount == 10000 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setSuperstakeRCCBalError('');
														setSuperstakeRCCAmount(10000);
														fetchUserData();
														if (rccBalance < 10000*1000000) {
															setSuperstakeRCCBalError(t('rcc-insufficient-bal'));
														}
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>10000 RCC</strong></p>
													</button>
												</div>
											</div>

											<p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(superstakeRCCAmount).toFixed(0)} RCC + {parseFloat(superstakeRCCAmount*0.5).toFixed(0)} RCC</strong></p>
											<p className="profit-green mb-3" style={{fontSize:"16px"}}><strong>(+50% RCC)</strong></p>

											{superstakeRCCAmount == 300 ? <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(0.2).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{superstakeRCCAmount == 1000 ? <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(0.4).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{superstakeRCCAmount == 3000 ? <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(0.6).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{superstakeRCCAmount == 5000 ? <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(0.8).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{superstakeRCCAmount == 10000 ? <p className="text-white mb-0" style={{fontSize:"24px"}}><strong>{parseFloat(1).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											
											<div className="w-100 text-center my-3">
												<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
											</div>

											{superstakeRCCAmount == 300 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(superstakeRCCAmount*1.5*0.2/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{superstakeRCCAmount == 1000 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(superstakeRCCAmount*1.5*0.4/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{superstakeRCCAmount == 3000 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(superstakeRCCAmount*1.5*0.6/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{superstakeRCCAmount == 5000 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(superstakeRCCAmount*1.5*0.8/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{superstakeRCCAmount == 10000 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(superstakeRCCAmount*1.5*1/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											
											{superstakeRCCBalError != "" ? <p className="error mt-3 mb-0" style={{fontSize:"14px"}}>{superstakeRCCBalError}</p> : <></>}
											<button disabled={superstakeRCCAmount < 300 || superstakeRCCBalError != ""} className="btn custom-btn mt-2" onClick={(e) => {
												submitSuperStakeRCC();
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('staking')} RCC</strong></h5>
											</button>
										</>
									):(
										<>
											<h5 className="profit-green"><strong>{t('staking-success')}</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('staking-success-desc')}</strong></p>
											<button className="btn custom-btn mt-3" onClick={(e) => {
												setSuperstakeRCCAmount(300);
												setStakeSuccess(false);
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
											</button>
										</>
									)}
								</div>
							)}
							<h5 className="brand-blue mt-5">{t('my-staking-records')}</h5>
							{userStakingData && userStakingData.length > 0 ? (
								<>
									{userStakingData && userStakingData.map((stakingData, index) => (
										<>
											{stakingData.token == "SuperRCC" ? (
												<>
													<div className="mb-3">
														<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>{t('staking')}: <span className="text-white">{parseFloat(stakingData.amount).toFixed(0)} RCC ({stakingData.lock_days} {t('days')})</span></strong></p>
														<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('staking-time')}: <span className="text-white">{Moment(stakingData.createdAt).format('YYYY/MM/DD HH:mm')}H</span></strong></p>
													</div>
												</>
											):(
												<>
												</>
											)}
										</>
									))}
								</>
							):(
								<p className="mb-0">
									No records
								</p>
							)}
						</div>
					</div>
				</div>


				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('stake-rc-title')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							{isStaking ? (
								<div className="w-100 text-center mt-3">
									<h5 className="brand-yellow"><strong>{t('stake-processing')}</strong></h5>
									<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-loading2')}</strong></p>
								</div>
							):(
								<div className="w-100 text-center mt-3">
									{!stakeSuccess ? (
										<>
											<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">{t('on-chain')}{t('market-wallet')}:</span> {userData && parseFloat(rcBalance/1000000000000000000).toFixed(2)}</p>
											<input
												type="number"
												placeholder={t('market-wallet-placeholder')}
												name='swapAmount'
												className='form-control text-center'
												onChange={handleStakeRCAmount}
											/>
											{stakeRCBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{stakeRCBalError}</p> : <></>}

											<div className="row mb-3">
												{/* <div className="col-6 pr-2">
													<button className={stakeRCDays == 45 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setStakeRCDays(45);
														fetchUserData();
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>45 {t('days')}</strong></p>
													</button>
												</div> */}
												<div className="col-6 pr-2">
													<button className={stakeRCDays == 90 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setStakeRCDays(90);
														fetchUserData();
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>90 {t('days')}</strong></p>
													</button>
												</div>
												<div className="col-6 pl-2">
													<button className={stakeRCDays == 180 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setStakeRCDays(180);
														fetchUserData();
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>180 {t('days')}</strong></p>
													</button>
												</div>
											</div>

											{stakeRCDays == 90 ? <p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(0.5).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{stakeRCDays == 180 ? <p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(1).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											
											<div className="w-100 text-center my-3">
												<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
											</div>

											{stakeRCDays == 90 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(stakeRCAmount*0.5/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{stakeRCDays == 180 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(stakeRCAmount*1/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}

											<button disabled={stakeRCAmount == 0 || invalidStakeRCAmount} className="btn custom-btn mt-3" onClick={(e) => {
												submitStakeRC();
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('swap-exchange')}</strong></h5>
											</button>
										</>
									):(
										<>
											<h5 className="profit-green"><strong>{t('staking-success')}</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('staking-success-desc')}</strong></p>
											<button className="btn custom-btn mt-3" onClick={(e) => {
												setStakeRCAmount(0);
												setStakeSuccess(false);
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
											</button>
										</>
									)}
								</div>
							)}
							<h5 className="brand-blue mt-5">{t('my-swap-history')}</h5>
							{userStakingData && userStakingData.length > 0 ? (
								<>
									{userStakingData && userStakingData.map((stakingData, index) => (
										<>
											{stakingData.token == "RC" ? (
												<>
													<div className="mb-3">
														<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>{t('swap-exchange')}: <span className="text-white">{parseFloat(stakingData.amount).toFixed(0)} RC ({stakingData.lock_days} {t('days')})</span></strong></p>
														<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('staking-time')}: <span className="text-white">{Moment(stakingData.createdAt).format('YYYY/MM/DD HH:mm')}H</span></strong></p>
													</div>
												</>
											):(
												<></>
											)}
										</>
									))}
								</>
							):(
								<p className="mb-0">
									No records
								</p>
							)}
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('stake-rcc-title')}</h3>
					</div>
				</div>

				<div className="row">
					<div className="col-12 mt-2">
						<div className="market-box text-center">
							{isStaking ? (
								<div className="w-100 text-center mt-3">
									<h5 className="brand-yellow"><strong>{t('stake-processing')}</strong></h5>
									<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('market-swap-loading2')}</strong></p>
								</div>
							):(
								<div className="w-100 text-center mt-3">
									{!stakeSuccess ? (
										<>
											<p className="text-white mb-2" style={{fontSize:"16px"}}><span className="brand-yellow">{t('rcc-balance')}:</span> {userData && parseFloat(rccBalance/1000000).toFixed(2)}</p>
											<input
												type="number"
												placeholder={t('rcc-amount-placeholder')}
												name='swapAmount'
												className='form-control text-center'
												onChange={handleStakeRCCAmount}
											/>
											{stakeRCCBalError != "" ? <p className="error mt-2 mb-0" style={{fontSize:"14px"}}>{stakeRCCBalError}</p> : <></>}

											<div className="row mb-3">
												<div className="col-6 pr-2">
													<button className={stakeRCCDays == 60 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setStakeRCCDays(60);
														fetchUserData();
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>60 {t('days')}</strong></p>
													</button>
												</div>
												<div className="col-6 pl-2">
													<button className={stakeRCCDays == 90 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setStakeRCCDays(90);
														fetchUserData();
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>90 {t('days')}</strong></p>
													</button>
												</div>
												<div className="col-12">
													<button className={stakeRCCDays == 180 ? "btn option-btn option-btn-selected mt-3" : "btn option-btn mt-3"} onClick={(e) => {
														setStakeRCCDays(180);
														fetchUserData();
													}}>
														<p className="text-white mb-0" style={{fontSize:"18px"}}><strong>180 {t('days')}</strong></p>
													</button>
												</div>
											</div>

											{stakeRCCDays == 30 ? <p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(1).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{stakeRCCDays == 60 ? <p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(1.5).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{stakeRCCDays == 90 ? <p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(2).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											{stakeRCCDays == 180 ? <p className="text-white mb-0 mt-3" style={{fontSize:"24px"}}><strong>{parseFloat(2.5).toFixed(2)}% / {t('days')}</strong></p> : <></>}
											
											<div className="w-100 text-center my-3">
												<p className="brand-blue mb-0" style={{fontSize:"24px"}}><i className="fa-solid fa-circle-arrow-down"></i></p>
											</div>

											{stakeRCCDays == 30 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(stakeRCCAmount*1/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{stakeRCCDays == 60 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(stakeRCCAmount*1.5/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{stakeRCCDays == 90 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(stakeRCCAmount*2/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}
											{stakeRCCDays == 180 ? <p className="profit-green mb-0" style={{fontSize:"24px"}}><strong>+{parseFloat(stakeRCCAmount*2.5/100).toFixed(2)} <span className="text-white">RCC / {t('days')}</span></strong></p> : <></>}

											<button disabled={stakeRCCAmount == 0 || invalidStakeRCCAmount} className="btn custom-btn mt-3" onClick={(e) => {
												submitStakeRCC();
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('staking')} RCC</strong></h5>
											</button>
										</>
									):(
										<>
											<h5 className="profit-green"><strong>{t('staking-success')}</strong></h5>
											<p className="text-white mb-0" style={{fontSize:"14px"}}><strong>{t('staking-success-desc')}</strong></p>
											<button className="btn custom-btn mt-3" onClick={(e) => {
												setStakeRCCAmount(0);
												setStakeSuccess(false);
											}}>
												<img className="w-100" src={CustomBtn}></img>
												<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
											</button>
										</>
									)}
								</div>
							)}
							<h5 className="brand-blue mt-5">{t('my-staking-records')}</h5>
							{userStakingData && userStakingData.length > 0 ? (
								<>
									{userStakingData && userStakingData.map((stakingData, index) => (
										<>
											{stakingData.token == "RCC" ? (
												<>
													<div className="mb-3">
														<p className="brand-blue mb-0" style={{fontSize:"12px"}}><strong>{t('staking')}: <span className="text-white">{parseFloat(stakingData.amount).toFixed(0)} RCC ({stakingData.lock_days} {t('days')})</span></strong></p>
														<p className="brand-yellow mb-0" style={{fontSize:"12px"}}><strong>{t('staking-time')}: <span className="text-white">{Moment(stakingData.createdAt).format('YYYY/MM/DD HH:mm')}H</span></strong></p>
													</div>
												</>
											):(
												<>
												</>
											)}
										</>
									))}
								</>
							):(
								<p className="mb-0">
									No records
								</p>
							)}
						</div>
					</div>
				</div>

			</div>
		</div>
		</>
	);

};

export default StakingPage;