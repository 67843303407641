// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import MiddleEllipsis from "react-middle-ellipsis";
// import Moment from 'moment';

import { setLanguage, t } from './translation.js';

import MainLogo from './assets/img/logo.png';
import HeaderLogo from './assets/img/logo-text.png';
import CustomBtn from './assets/img/custom-btn.png';

// Bottom Menu Icons
import MiningMenu from './assets/img/mining.png';
import MarketMenu from './assets/img/market.png';
import TeamMenu from './assets/img/team.png';
import ProfileMenu from './assets/img/profile.png';
import ShopMenu from './assets/img/shop.png';
import MiningMenuSelected from './assets/img/mining-selected.png';
import MarketMenuSelected from './assets/img/market-selected.png';
import TeamMenuSelected from './assets/img/team-selected.png';
import ProfileMenuSelected from './assets/img/profile-selected.png';
import ShopMenuSelected from './assets/img/shop-selected.png';

// Noticeboard
import Notice1 from './assets/img/notice1.jpg';
import Notice2 from './assets/img/notice2.jpg';


import { isAddress } from 'ethers/lib/utils';

// Pages
import ProfilePage from './pages/profile';
import EarningPage from './pages/earning';
import TeamPage from './pages/team';
import MarketPage from './pages/market';
import MiningPage from './pages/mining';
import ShopPage from './pages/shop';
import StakingPage from './pages/staking';

// hooks
import { useSignup } from './hooks/useSignup';


function App() {

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('zh');
  const handleLanguageChange = (language) => {
    setLanguage(language);
    setCurrentLanguage(language);
    setIsLanguageModalOpen(false);
  };

  const queryParameters = new URLSearchParams(window.location.search);
  var referrerWallet = queryParameters.get("referral");

  const [miningSelected, setMiningSelected] = useState(false);
  const [marketSelected, setMarketSelected] = useState(false);
  const [teamSelected, setTeamSelected] = useState(false);
  const [profileSelected, setProfileSelected] = useState(true);
  const [shopSelected, setShopSelected] = useState(false);
  const [stakingSelected, setStakingSelected] = useState(false);

  const [noticeboardClicked, setNoticeboardClicked] = useState(false);

  const { walletSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess } = useSignup();

  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

	const userRegister = async () => {
    if (referrerWallet == null || referrerWallet == "") {
      referrerWallet = "0xaf63c43c049194682e858c9cf8cc87f4cefb61f9";
    }
    console.log("Final Wallet:", referrerWallet);
    if (referrerWallet.toLowerCase() == blockchain.account.toLowerCase()) {
      setRegisterFeedback("不能推荐自己的钱包地址！");
      return;
    }
    if (!isAddress(referrerWallet) || referrerWallet.length < 42) {
      setRegisterFeedback("钱包地址不符合标准，请检查链接！");
      return;
    }

    await walletSignup(blockchain.account, referrerWallet);

    setTimeout(() => fetchUserData(), 2000);
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.rideChain !== null) {
      fetchUserData();
    }
  };

  useEffect(() => {
    getData();
    console.log("Referrer Wallet from URL:", referrerWallet);
  }, [blockchain.account]);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">
        <Router>
          {isLanguageModalOpen && (
            <div
              className="modal fade show"
              style={{ display: "block", paddingTop: "100px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              aria-modal="true"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-top" role="document">
                <div className="modal-content bg-dark p-3">
                  <div className="modal-header" style={{ borderBottom:'2px solid #00FFFF'}}>
                    <h5 className="modal-title text-white">{t('select-language')}</h5>
                    <button
                      type="button"
                      className="btn-close bg-transparent"
                      aria-label="Close"
                      onClick={() => setIsLanguageModalOpen(false)}
                      style={{ border:'none', fontSize:'18px' }}
                    >
                      <i className="fa-solid fa-circle-xmark text-white"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <ul className="list-group">
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "zh" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("zh")}
                        >
                          中文
                        </button>
                      </li>
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "en" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("en")}
                        >
                          English
                        </button>
                      </li>
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "ko" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("ko")}
                        >
                          한국어 (Korean)
                        </button>
                      </li>
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "ja" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("ja")}
                        >
                          日本語 (Japanese)
                        </button>
                      </li>
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "th" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("th")}
                        >
                          ภาษาไทย
                        </button>
                      </li>
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "id" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("id")}
                        >
                          Bahasa Indonesia
                        </button>
                      </li>
                      <li className="list-group-item border-0 bg-transparent">
                        <button
                          className={`btn btn-block text-start font-weight-bold ${
                            currentLanguage === "vi" ? "brand-blue" : "text-white"
                          }`}
                          onClick={() => handleLanguageChange("vi")}
                        >
                          Tiếng Việt
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {userData && userData.referrer != "" && noticeboardClicked ? (
            <>
              {/* Header */}
              <section className="header">
                <div className="container">
                  <div className="row d-flex align-items-center">
                    <div className="col-md-5 p-0 text-center text-md-left">
                      <img className="header-logo" src={HeaderLogo}></img>
                    </div>
                    <div className="col-md-7 p-0 text-center text-md-right mt-2 mt-md-0">
                      <div className="language-switch">
                        <button id="language-select" className="btn px-4 font-weight-bold" onClick={() => setIsLanguageModalOpen(true)}>
                          {currentLanguage == "zh" ? "中文" : ""}
                          {currentLanguage == "en" ? "English" : ""}
                          {currentLanguage == "ko" ? "한국어 (Korean)" : ""}
                          {currentLanguage == "ja" ? "日本語 (Japanese)" : ""}
                          {currentLanguage == "th" ? "ภาษาไทย" : ""}
                          {currentLanguage == "id" ? "Bahasa Indonesia" : ""}
                          {currentLanguage == "vi" ? "Tiếng Việt" : ""}
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </>
          ) : (
            <></>
          )}
          
          <Routes>
            <Route path="/" element={
              !blockchain.account ? (
                <>
                  <section className="login-bg">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 text-center px-2">
                          <img className="main-logo" src={MainLogo}></img>
                          <br></br>
                          {blockchain.account === "" || blockchain.rideChain === null ? (
                            <>
                              <button className="btn custom-btn mt-5" onClick={(e) => {
                                e.preventDefault();
                                dispatch(connect());
                                getData();
                              }}>
                                <img className="w-100" src={CustomBtn}></img>
                                <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('connect-wallet')}</strong></h5>
                              </button>
                              <br></br>
                              <button className="btn custom-btn mt-3" onClick={(e) => {
                                window.open("https://whitepaper.ridechain.io/", "_blank");
                              }}>
                                <img className="w-100" src={CustomBtn}></img>
                                <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('whitepaper')}</strong></h5>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  {!userData || userData.referrer == "" ? (
                    <section className="main-content register-bg">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 text-center px-2">
                          <img className="main-logo" src={MainLogo}></img>
                            <div className="referral-area">
                              <div className="data-box py-5">
                                <h5 className="text-white">{t('confirm-r-address')}:</h5>
                                <MiddleEllipsis><span className="text-white mt-1 mb-3" style={{fontSize:"12px"}}>{referrerWallet ? referrerWallet : t('main-no-referral')}</span></MiddleEllipsis>
                                {!registerSuccess ? (
                                  <>
                                    <p className="error mt-1" style={{fontSize:"12px"}}>{registerFeedback}</p>
                                    {!registering ? (
                                      <>
                                        <button className="btn custom-btn" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="500" onClick={(e) => {
                                          userRegister();
                                        }}>
                                          <img className="w-100" src={CustomBtn}></img>
                                          <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('register')}</strong></h5>
                                        </button>
                                      </>
                                    ):(
                                      <></>
                                    )}
                                  </>
                                ):(
                                  <p className="profit-green mt-1" style={{fontSize:"12px"}}>{registerFeedback}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  ):(
                    <>
                      {!noticeboardClicked ? (
                        <div className="container mt-3 pb-5">
                          <div className="row">
                            <div className="col-12">
                              <img className="w-100" src={Notice1}/>
                              <img className="w-100 mt-3" src={Notice2}/>
                            </div>
                            <div className="col-12 text-center">
                              <button className="btn custom-btn mt-3" onClick={(e) => {
                                setNoticeboardClicked(true);
                                window.scrollTo(0, 0);
                              }}>
                                <img className="w-100" src={CustomBtn}></img>
                                <h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('confirm')}</strong></h5>
                              </button>
                            </div>
                          </div>
                        </div>
                      ):(
                        <Navigate to="/profile" />
                      )}
                    </>
                  )}
                </>
              )
            }/>
          </Routes>


          <Routes>
            <Route path="/profile" element={blockchain.account ? <ProfilePage/> : <Navigate to="/" />}/>
            <Route path="/earning" element={blockchain.account ? <EarningPage/> : <Navigate to="/" />}/>
            <Route path="/team" element={blockchain.account ? <TeamPage/> : <Navigate to="/" />}/>
            <Route path="/market" element={blockchain.account ? <MarketPage/> : <Navigate to="/" />}/>
            <Route path="/mining" element={blockchain.account ? <MiningPage/> : <Navigate to="/" />}/>
            <Route path="/shop" element={blockchain.account ? <ShopPage/> : <Navigate to="/" />}/>
            <Route path="/staking" element={blockchain.account ? <StakingPage/> : <Navigate to="/" />}/>
          </Routes>


          {userData && userData.referrer != "" && noticeboardClicked ? (
            <>
              {/* Bottom Menu */}
              <section className="bottom-menu">
                <div className="container">
                  <div className="row">
                    <div className="col-12 px-2 ">
                      <div className="d-flex justify-content-center">

                      <NavLink to="/shop">
                        <button className="btn py-0 px-1" onClick={(e) => {
                            window.scrollTo(0, 0);
                            setMiningSelected(false);
                            setMarketSelected(false);
                            setTeamSelected(false);
                            setProfileSelected(false);
                            setShopSelected(true);
                            setStakingSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!shopSelected ? ShopMenu : ShopMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>{t('shop')}</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/market">
                        <button className="btn py-0 px-1" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(true);
                          setTeamSelected(false);
                          setProfileSelected(false);
                          setShopSelected(false);
                          setStakingSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!marketSelected ? MarketMenu : MarketMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>{t('market')}</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/staking">
                        <button className="btn py-0 px-1" onClick={(e) => {
                            window.scrollTo(0, 0);
                            setMiningSelected(false);
                            setMarketSelected(false);
                            setTeamSelected(false);
                            setProfileSelected(false);
                            setShopSelected(false);
                            setStakingSelected(true);
                        }}>
                          <span className="bottom-menu-icon"><i className={!stakingSelected ? "fa-solid fa-coins icon-unselected" : "fa-solid fa-coins icon-selected"}></i></span>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>{t('staking')}</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/mining">
                        <button className="btn py-0 px-1" onClick={(e) => {
                            window.scrollTo(0, 0);
                            setMiningSelected(true);
                            setMarketSelected(false);
                            setTeamSelected(false);
                            setProfileSelected(false);
                            setShopSelected(false);
                            setStakingSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!miningSelected ? MiningMenu : MiningMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>{t('mining')}</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/team">
                        <button className="btn py-0 px-1" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(false);
                          setTeamSelected(true);
                          setProfileSelected(false);
                          setShopSelected(false);
                          setStakingSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!teamSelected ? TeamMenu : TeamMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>{t('team')}</strong></p>
                        </button>
                      </NavLink>

                      <NavLink to="/profile">
                        <button className="btn py-0 px-1" onClick={(e) => {
                          window.scrollTo(0, 0);
                          setMiningSelected(false);
                          setMarketSelected(false);
                          setTeamSelected(false);
                          setProfileSelected(true);
                          setShopSelected(false);
                          setStakingSelected(false);
                        }}>
                          <img className="bottom-menu-icon" src={!profileSelected ? ProfileMenu : ProfileMenuSelected}></img>
                          <p className="text-white mt-1 mb-0" style={{fontSize:"9px"}}><strong>{t('profile')}</strong></p>
                        </button>
                      </NavLink>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </>
          ) : (
            <></>
          )}

        </Router>
      </div>
    </div>
  );
}

export default App;