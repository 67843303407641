const initialState = {
  loading: false,
  account: null,
  rideChain: null,
  rcToken: null,
  rccToken: null,
  rcPlusToken: null,
  rcPlusHandler: null,
  rcPlusHandlerV2: null,
  tokenPair: null,
  usdtContract: null,
  otcMarket: null,
  web3: null,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        rideChain: action.payload.rideChain,
        rcToken: action.payload.rcToken,
        rccToken: action.payload.rccToken,
        rcPlusToken: action.payload.rcPlusToken,
        rcPlusHandler: action.payload.rcPlusHandler,
        rcPlusHandlerV2: action.payload.rcPlusHandlerV2,
        tokenPair: action.payload.tokenPair,
        usdtContract: action.payload.usdtContract,
        otcMarket: action.payload.otcMarket,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
