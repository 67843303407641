import { CONFIG } from '../config';
import React, { useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';

import { setLanguage, t } from '../translation.js';

import CustomBtn from '../assets/img/custom-btn.png';

import SmallDataBox from '../assets/img/box-small.png';
import BigDataBox from '../assets/img/box-big.png';

import TrialPackage from '../assets/img/trial-package.png';
import Package1 from '../assets/img/package1.png';
import Package2 from '../assets/img/package2.png';
import Package3 from '../assets/img/package3.png';
import Package4 from '../assets/img/package4.png';
import Package5 from '../assets/img/package5.png';
import Package6 from '../assets/img/package6.png';
import Package7 from '../assets/img/package7.png';

import Mining1 from '../assets/img/mining1.png';
import Mining2 from '../assets/img/mining2.png';
import Mining3 from '../assets/img/mining3.png';
import Mining4 from '../assets/img/mining4.png';
import Mining5 from '../assets/img/mining5.png';
import Mining6 from '../assets/img/mining6.png';
import Mining7 from '../assets/img/mining7.png';

// hooks
import { useWithdrawal } from '../hooks/useWithdrawal';

import { isAddress } from 'ethers/lib/utils';

const MiningPage = () => {

	const blockchain = useSelector((state) => state.blockchain);

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
	  const json = await response.json();
	  if (response.ok) {
		  if (json.length === 1) {
			// Expect only 1 result
			console.log(json[0]);
			setUserData(json[0]);
		  } else {
			console.log("Invalid user data: More than 1 result.");
		  }
	  }
	};

	const [investData, setInvestData] = useState([]);
	const fetchInvestData = async () => {
		const response = await fetch('https://api.ridechain.io/deposit/wallet/'+blockchain.account);
		const json = await response.json();
		if (response.ok) {

			console.log(json);
			setInvestData(json);

		}
	};

  	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
		blockchain.rideChain.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
		.then((results) => {
			console.log("USDT Balance:", results);
			setUSDTBalance(results);
		});
	};

  	const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
		blockchain.usdtContract.methods.allowance(blockchain.account, "0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4").call()
		.then((results) => {
			console.log("Token Allowance:", results);
			setAllowance(results);
		});
	};

  	const [approving, setApproving] = useState(false);
	const approveToken = () => {
		setApproving(true);
		blockchain.usdtContract.methods
		.approve("0xCb9a0Ee0357960d53E6dE6A032723d9668f9A2d4", blockchain.web3.utils.toWei((100000).toString(), "ether"))
		.send({
			gasLimit: 80000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setApproving(false);
		})
		// Transaction success
		.then((receipt) => {
			setTimeout(() => checkTokenAllowance(), 3000);
			setTimeout(() => setApproving(false), 5000);
		});
	};

	const { submitWithdraw, setIsWithdrawing, isWithdrawing, withdrawSuccess, setWithdrawSuccess } = useWithdrawal();

	const userWithdraw = async (_amount) => {
		await submitWithdraw(userData._id, blockchain.account, _amount, "Static");
		setTimeout(() => fetchUserData(), 3000);
	};


  	const [investAmount, setInvestAmount] = useState(300);

	const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = (_amount) => {
	console.log("Submit deposit with referrer:", userData.referrer);
	setInvesting(true);
	blockchain.rideChain.methods
	.invest(_amount, true, "0x227482dA91F856334876E40ce63841Dbf4885506", userData.referrer)
	.send({
		gasLimit: 1050000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	})
	// Transaction fail
	.once("error", (err) => {
		setInvesting(false);
	})
	// Transaction success
	.then((receipt) => {
		fetch('https://api.ridechain.io/deposit/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
				referrer: userData.referrer,
				user_id: userData._id,
				wallet: blockchain.account,
				deposit_amount: Number(_amount)
            })
        });
		setInvestSuccess(true);
		setTimeout(() => fetchInvestData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	});
	};
	
	useEffect(() => {
		// getData();
		fetchUserData();
		getUserUSDTBalance();
		checkTokenAllowance();
		fetchInvestData();
	}, []);


	return (
		<>
		<div className="main-content package-bg">

			{/* Staking Modal */}
			<div class="modal fade" id="stakeModal" tabindex="-1" data-keyboard="false" aria-labelledby="stakeModalLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content invest-modal py-3">
					<div class="modal-body text-center px-3">
						{allowance < investAmount*1000000000000000000 ? (
						<>
							{!approving ? (
							<>
								<h5 className="text-white mb-0">{t('mining-auth-text')}</h5>
								<p className="text-white mb-0">{t('mining-auth-text2')}</p>
								<button className="btn custom-btn mt-3" onClick={(e) => {
								approveToken();
								}}>
								<img className="w-100" src={CustomBtn}></img>
								<h5 className="custom-btn-text text-white mb-3" style={{fontSize:"18px"}}><strong>{t('mining-auth-btn')}</strong></h5>
								</button>
							</>
							):(
							<p className="brand-yellow mb-0" style={{fontSize:"16px"}}>{t('mining-auth-load')}</p>
							)}

						</>
						):(
						<>
							{!investing ? (
							<>	
								{!investSuccess ? (
								<>
									<h5 className="text-white">{t('mining-confirm')}</h5>
									<p className="brand-blue mb-0" style={{fontSize:"32px"}}><strong>{investAmount} USDT</strong></p>
									{investAmount > usdtBalance/1000000000000000000 ? (
									<p className="error mt-2 mb-0" style={{fontSize:"18px"}}><strong>{t('mining-alert')}</strong></p>
									):(
									<button className="btn custom-btn mt-3" onClick={(e) => {
										investUSDT(investAmount);
									}}>
										<img className="w-100" src={CustomBtn}></img>
										<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('mining-buy-btn')}</strong></h5>
									</button>
									)}
									<p className="text-white mt-2 mb-0" style={{fontSize:"14px"}}><span className="text-gray">{t('my-wallet')}</span>{parseFloat(usdtBalance/1000000000000000000).toFixed(2)} USDT</p>

								</>
								):(
								<>
									<span style={{fontSize:"36px"}}><i className="profit-green fa-sharp fa-solid fa-circle-check mr-2"></i></span>
									<h5 className="text-white mt-2 mb-0">{t('mining-buy-success')}</h5>
									<button className="btn custom-btn mt-3" data-dismiss="modal" onClick={(e) => {
										setInvestAmount(300);
										checkTokenAllowance();
										setTimeout(() => setInvestSuccess(false), 1000);
									}}>
									<img className="w-100" src={CustomBtn}></img>
									<h5 className="custom-btn-text text-white mb-3 mb-0" style={{fontSize:"18px"}}><strong>{t('complete-btn')}</strong></h5>
									</button>
								</>
								)}

							</>
							):(
							<p className="text-white mb-0" style={{fontSize:"14px"}}>{t('mining-buy-processing')}</p>
							)}

						</>
						)}



					</div>
					</div>
				</div>
			</div>

			<div className="container-fluid mining-bg py-5">

				<div className="row">
					<div className="col-12 text-center">
						<h3 className="brand-blue">{t('mining-title')}</h3>
					</div>
				</div>

				<div className="row px-3">
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(100);
						}}>
						<img className="w-100" src={TrialPackage}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(300);
						}}>
						<img className="w-100" src={Package1}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(1000);
						}}>
						<img className="w-100" src={Package2}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(3000);
						}}>
						<img className="w-100" src={Package3}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(5000);
						}}>
						<img className="w-100" src={Package4}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(10000);
						}}>
						<img className="w-100" src={Package5}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(30000);
						}}>
						<img className="w-100" src={Package6}></img>
						</button>
					</div>
					<div className="col-6 text-center px-2 my-3">
						<button className="btn p-0" data-toggle="modal" data-target="#stakeModal" onClick={(e) => {
							setInvestSuccess(false);
							setInvestAmount(50000);
						}}>
						<img className="w-100" src={Package7}></img>
						</button>
					</div>
				</div>

			</div>


			<div className="container-fluid py-5">
				<div className="row">

					{/* <div className="col-12 text-center mt-5">
						<h3 className="brand-blue">我的挖矿收益</h3>
					</div>

					<div className="col-12 text-center">
						<div className="data-container">
							<img className="w-100" src={SmallDataBox}></img>
							<div className="data-text text-left">
								<p className="text-white mb-0" style={{fontSize:"16px"}}><strong>可提现挖矿收益: <br></br> <span className="profit-green" style={{fontSize:"24px"}}>{userData && parseFloat(userData.mining_profit || 0).toFixed(2)}</span></strong> </p>
							</div>
						</div>
					</div> */}


					<div className="col-12 text-center mt-5">
						<h3 className="brand-blue">{t('mining-my-packages')}</h3>
						<div className="row">
							{investData && investData.length > 0 ? (
								<>
									{investData && investData.map((investment, index) => (
										<div key={index}>
											<div className="col-12 mt-2">
												{investment.deposit_amount <= 300 && investment.deposit_amount < 1000 ? <img className="w-100" src={Mining1}></img> : <></>}
												{investment.deposit_amount >= 1000 && investment.deposit_amount < 3000 ? <img className="w-100" src={Mining2}></img> : <></>}
												{investment.deposit_amount >= 3000 && investment.deposit_amount < 5000 ? <img className="w-100" src={Mining3}></img> : <></>}
												{investment.deposit_amount >= 5000 && investment.deposit_amount < 10000 ? <img className="w-100" src={Mining4}></img> : <></>}
												{investment.deposit_amount >= 10000 && investment.deposit_amount < 30000 ? <img className="w-100" src={Mining5}></img> : <></>}
												{investment.deposit_amount >= 30000 && investment.deposit_amount < 50000 ? <img className="w-100" src={Mining6}></img> : <></>}
												{investment.deposit_amount >= 50000 ? <img className="w-100" src={Mining7}></img> : <></>}
											</div>
											<div className="col-12 mt-2 mb-3">
												<div className="data-box">
													<p className="text-white mb-0" style={{fontSize:"28px"}}><strong>{parseFloat(investment.deposit_amount).toFixed(0)} USDT</strong></p>
													<h5 className="profit-green mb-0">{t('mining-status')}</h5>
												</div>
											</div>
										</div>
									))}
								</>
							):(
								<div className="col-12 mt-2">
									<div className="data-box">
										<div className="row">
											<div className="col-12 text-center">
												<p className="text-white mb-0">{t('mining-status2')}</p>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>


		</div>
		</>
	);

};

export default MiningPage;